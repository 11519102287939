import type { PropsWithChildren } from 'react';
import type { Organization, Place, Thing, WithContext } from 'schema-dts';
export function JsonLd<T extends Thing>({
  data
}: PropsWithChildren<Props<T>>): JSX.Element {
  const json: WithContext<T> = {
    '@context': 'https://schema.org',
    ...(data as any)
  };
  return <script type='application/ld+json' dangerouslySetInnerHTML={{
    __html: JSON.stringify(json)
  }} data-sentry-component="JsonLd" data-sentry-source-file="index.tsx" />;
}
export const ADDRESS_JSON: Place = {
  '@type': 'Place',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'US',
    addressRegion: 'CA',
    streetAddress: '120 Hawthorne Avenue',
    postalCode: '94301',
    addressLocality: 'Palo Alto'
  }
};
export const ORGANIZATION_JSON: Organization = {
  '@type': 'Organization',
  name: 'Step',
  url: 'https://step.com',
  legalName: 'Step Mobile, Inc.',
  logo: 'https://step.com/assets/logo-dark.png',
  address: ADDRESS_JSON.address,
  sameAs: ['https://step.com', 'https://www.instagram.com/stepmobile/', 'https://twitter.com/step', 'https://www.facebook.com/step', 'https://www.linkedin.com/company/stepmobile'],
  contactPoints: [{
    '@type': 'ContactPoint',
    email: 'hello@step.com'
  }]
};
interface Props<T> {
  data: T;
}