import type { NextPage } from 'next';
import Head from 'next/head';
import type { Organization, WebSite } from 'schema-dts';
import { JsonLd, ORGANIZATION_JSON } from '../components/json-ld';
import type { StaticPageData } from '../lib/static/page';
import { createStaticPage, getContenfulStaticProps } from '../lib/static/static-page';
import { useClickTracker } from '../lib/store/tracker/ClickTracker';
import { PAGE_OPTIONS } from '../lib/utils/page';
export const getStaticProps = getContenfulStaticProps(PAGE_OPTIONS.HOME.slug);
const Page = createStaticPage(PAGE_OPTIONS.HOME);
const Home: NextPage<StaticPageData> = pageProps => {
  useClickTracker({
    feature: 'get-app-button-homepage'
  });
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
                <meta key='facebook-domain-verification' name='facebook-domain-verification' content='xoedfnjptcbqfjuuml3pb26v58g5vp' data-sentry-element="meta" data-sentry-source-file="index.tsx" />
            </Head>

            <Page {...pageProps} data-sentry-element="Page" data-sentry-source-file="index.tsx" />

            <JsonLd<Organization> data={ORGANIZATION_JSON} data-sentry-element="JsonLd" data-sentry-source-file="index.tsx" />

            <JsonLd<WebSite> data={{
      '@type': 'WebSite',
      name: 'Step',
      url: 'https://step.com/'
    }} data-sentry-element="JsonLd" data-sentry-source-file="index.tsx" />
        </>;
};
export default Home;